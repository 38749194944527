import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/logo.png";

const Splash = () => {
	const history = useHistory();

	useEffect(() => {
		const timeId = setTimeout(() => {
			history.push("/welcome");
		}, 6000);
		return () => {
			clearTimeout(timeId);
		};
	}, []);
	return (
		<div className={"h-full"}>
			<div className={"mx-auto p-2 h-3/4"}>
				<img src={logo} className={"mx-auto h-full"} alt={"Algoritmo Interactivo"} />
			</div>
			<div className={"mx-auto p-5"}>
				<Link to={"/welcome"} className={"transition duration-500 ease-in-out transform hover:scale-95 btn btn-block btn-primary btn-primary-gradient"}>
					Iniciar
				</Link>
			</div>
		</div>
	);
};

export default React.memo(Splash);
