import React from "react";
import { Link } from "react-router-dom";
function TextBreadcumsComponent({ links }) {
	return (
		<div className={"text-sm breadcrumbs hidden lg:block"}>
			<ul>
				{links.map((link, index) => (
					<li key={`linkto${index}`}>
						<Link to={link.to}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor">
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
								/>
							</svg>
							{link.title}
						</Link>
					</li>
				))}
			</ul>
		</div>
	);
}

export default TextBreadcumsComponent;
