import React from "react";
import { Link } from "react-router-dom";

const SideBarMenuComponent = () => {
	return (
		<div className="drawer-side">
			<label htmlFor="drawerMenu" className="drawer-overlay"></label>
			<ul className="menu p-4 overflow-y-auto w-80 bg-primary-dark text-neutral-content">
				<li>
					<Link to={"/welcome"}>
						<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
						</svg>&nbsp; Inicio
					</Link>
				</li>
				<li>
					<Link to={"/material"}>
						<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
						</svg> &nbsp;
						Material PDF
					</Link>
				</li>
				<li>
					<Link to={"/links"}>
						<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
						</svg> &nbsp;
						Enlaces de interés
					</Link>
				</li>
				<li>
					<Link to={"/privacy"}>
						<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" />
						</svg>&nbsp;Aviso de Privacidad,<br/>
						&nbsp;Aviso General,<br/>
						&nbsp;Términos y Condiciones <br/>
						&nbsp;y Uso de Cookies
					</Link>
				</li>
			</ul>
		</div>
	);
};

export default SideBarMenuComponent;
